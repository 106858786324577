


















































































import { defineComponent } from '@vue/composition-api'
import TopBar from '@/components/organisms/o-top-bar.vue'
import { mapActions, mapState } from 'vuex'
import Pagination from '@/components/base/Pagination.vue'
import {
  usePagination,
  PaginationOptions, parseQuery,
} from '@/composables/pagination'
import { QueryParams } from '@/store/api-plateform-utils'
import router from '@/router'
import { TrainingType } from '@/api/interfaces/trainingtype'
import { userCanAccess } from '@/composables/UserGrant'

export default defineComponent({
  setup (props, ctx) {
    const canAddTrainingType = () => userCanAccess('TrainingType Create')

    const query = {
      ...router.app.$route.query,
    }
    const totalItems = () => {
      return ctx.root.$store.state.trainingType.totalItems
    }
    const {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      }: PaginationOptions = usePagination(
      totalItems,
      parseQuery(query),
    ) as PaginationOptions

    return {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      canAddTrainingType,
    }
  },
  name: 'TrainingTypeList',
  components: {
    TopBar,
    Pagination,
  },
  async created () {
    this.load(this.baseRequest)
  },
  data () {
    return {
      title: this.$t('training_type.list.title_header'),
      headers: [
        {
          text: this.$t('training_type.list.headers.name'),
          align: 'start',
          value: 'name',
        },
        {
          text: this.$t('training_type.list.headers.duration'),
          value: 'duration',
        },
        {
          text: this.$t('training_type.list.headers.specificToyota'),
          value: 'specificToyota',
        },
        {
          text: this.$t('training_type.list.headers.color'),
          value: 'color',
        },
        {
          text: this.$t('training_type.list.headers.actions'),
          value: 'actions',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('trainingType', {
      trainingTypesList: 'listWithPagination',
      totalItems: 'totalItems',
      loading: 'loading',
    }),
    baseRequest (): QueryParams {
      const request: Record<string, unknown> = {
        ...this.paginationRequestParams,
      }
      return request as QueryParams
    },
  },
  watch: {
    paginationOptions: {
      handler () {
        this.loadData()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('trainingType', {
      load: 'loadWithPagination',
    }),
    loadData () {
      this.addParamsToLocation()
      this.load(this.baseRequest)
    },
    addParamsToLocation () {
      let query: any = {
        ...this.paginationOptions,
      }
      query = Object.fromEntries(
        Object.entries(query).filter(([key, value]) => !!value),
      )

      router.replace({ name: 'TrainingType List', query: query })
    },
    gotToTrainingTypeForm (item: TrainingType) {
      if (item.id) {
        this.$router.push({
          name: 'TrainingType Edit',
          params: { idTrainingType: item.id.toString() },
        })
      }
    },
    getItemClass (item: TrainingType) {
      return item.isActive ? '' : 'tr-item-inactive'
    },
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'TrainingType Edit' || to.name === 'TrainingType Create') {
      this.$store.commit('navigation/resetPile')
      this.$store.commit('navigation/addRoute', from)
    }
    next()
  },
})
